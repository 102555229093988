@import 'custom';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/transitions';

// Navbar
.strawberry-bg {
  background-image: url('../img/img01.jpg');
  background-position: top right;
  background-repeat: no-repeat;
  height: 474px;

  @media (min-width: 1920px) {
    background-position: top center;
  }
}

.strawberry-logo {
  margin-left: .5rem;
  padding-top: .7rem;
  position: relative;

  h1 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
  }

  &::before {
    content: url('../img/strawberry.svg');
    left: -15px;
    position: absolute;
    top: 1px;
    width: 19px;
  }

  @media (min-width: 578px) {
    margin-left: 1rem;

    h1 {
      font-size: 1.5rem;
    }

    &::before {
      left: -22px;
      top: 0;
      width: 30px;
    }
  }

  @media (min-width: 768px) {
    margin-left: 24px;

    h1 {
      font-size: 2rem;
    }

    &::before {
      left: -28px;
      top: 1px;
      width: 35px;
    }
  }

  @media (min-width: 992px) {
    margin-left: 1rem;

    h1 {
      font-size: 2.5rem;
    }

    &::before {
      left: -33px;
      top: -2px;
      width: 40px;
    }
  }
}

// Main

.dark-transparent-bg {
  background-color: rgba(0, 0, 0, .5);
}

.custom-card-header {
  background: lighten($dark, 20%);
  border: 0;
  border-left: solid 6px $primary;
  border-radius: 0;
  color: $white;
  padding: .25rem .75rem;

  @media (min-width: 768px) {
    padding: .75rem .6rem;
  }

  @media (min-width: 1096px) {
    padding: .75rem 1.25rem;
  }
}
