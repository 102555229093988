$body-bg: #FFF;
$body-color: #3C3C3B;
$primary: #DB4F40;
$secondary: #3C3C3B;
$light: #E9E9E9;
$dark: #7B7B7B;

$container-max-widths: (
  xl: 1560px
);
